body {
  margin: 0;
  padding: 0;
}
.card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-width: 90vw;
  margin: 0 auto;
}
.main-title {
  text-align: center;
  color: #70aaf7;
  font-size: 44px;
}

@media only screen and (max-width: 400px) {
  .card-container {
    display: flex;
    flex-direction: column;
  }
  .main-title {
    font-size: 28px;
  }
}
