.card {
  background-color: #6c6c626e;
  border: 1px solid grey;
  border-radius: 5px;
  padding: 25px;
  margin: 15px;
  text-align: center;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;
}

.card:hover {
  transform: scale(1.05);
}
.card a {
  text-decoration: none;
  color: #537791;
}
